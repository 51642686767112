import React from 'react';
import {
  MENU_ICON_ID,
  MENU_ICON_ITEM_ID1,
  MENU_ICON_ITEM_ID2,
  MENU_ICON_ITEM_ID3,
} from '@/constants';

export interface IMenuIconProps {
  showMenu: boolean;
  className?: string;
  handleShowMenu: () => void;
}
const MenuIcon = ({
  showMenu,
  className = '',
  handleShowMenu,
}: IMenuIconProps) => {
  return (
    <div className={className}>
      <div
        className="group flex h-4 w-5 cursor-pointer flex-col items-center justify-between"
        onClick={() => handleShowMenu()}
        id={MENU_ICON_ID}
      >
        <div
          id={MENU_ICON_ITEM_ID1}
          className={`ease h-0.5 w-5 rounded-full bg-black transition duration-300 ${
            showMenu ? 'translate-y-1.5 rotate-45 ' : ''
          }`}
        />
        <div
          id={MENU_ICON_ITEM_ID2}
          className={`ease h-0.5 w-5 rounded-full bg-black transition duration-300 ${
            showMenu ? 'opacity-0' : ''
          }`}
        />
        <div
          id={MENU_ICON_ITEM_ID3}
          className={`ease h-0.5 w-5 rounded-full bg-black transition duration-300 ${
            showMenu ? '-translate-y-2 -rotate-45 ' : ''
          }`}
        />
      </div>
    </div>
  );
};

export default MenuIcon;
